<template>
  <div>
    <frame :top1="top1" :top2="top2">
      <!-- 头部 -->
 
      <!-- 筛选部分 -->
      <div class="screen">
 
		<div style="margin-left: -88%;margin-bottom: 10px;">弹窗设置</div>
        <div class="screen_cont">
          <el-form
            size="small"
            :inline="true"
            :model="formInline"
            class="demo-form-inline"
			style="margin-left: 8.5%;border-radius: 10px;"
          >
     
            
            <el-form-item label="选择商户：" v-show="merchantShow">
              <el-select
                v-model="business_id"
                placeholder="请选择商户"
                @change="merchantChange(business_id)"
              >
                <el-option
                  v-for="item in businessList"
                  :key="item.business_id"
                  :label="item.business_name"
                  :value="item.business_id"
                >
                </el-option>
              </el-select>
			  <el-button @click="screen()" size="mini" type="danger" style="margin-left: 100px;">查询结果</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <!-- 表格头部 -->
      
      <!-- 表格渲染 -->
      <div class="table">
        <!-- 设置表格高度  height="500" -->
		<div class="bck">
			<div class="table_head" style="margin-left: 7.5%;">
			
			  <div class="table_head_right" >
			    <ul>
			    
			      <li v-if="tableData.length==0">
			        <el-button  size="mini" type="danger"  @click="show = !show" v-if="storeShow">
			          添加弹窗
			        </el-button>
			      </li>
			    </ul>
			    <div class="popContainer" v-show="show">
			      <transition name="el-fade-in">
			        <div class="transition-box">
			          <el-form
			            ref="form"
			            :model="sizeForm"
			            label-width="80px"
			            size="mini"
			          >
			            <div class="act_tit" style="mar">添加弹窗内容</div>
						
			            <el-form-item label="弹窗名称：" label-width="120px">
			              <el-input v-model="sizeForm.name"></el-input>
			            </el-form-item>
			           <el-form-item label="弹窗内容：" label-width="120px">
			             <el-input v-model="sizeForm.content"></el-input>
			           </el-form-item>
			         
			            
			            <el-form-item size="large">
			              <el-button type="danger"  @click="onSubmit"
			                >立即创建</el-button
			              >
			              <el-button @click="show = false">取消</el-button>
			            </el-form-item>
			          </el-form>
			        </div>
			      </transition>
			    </div>
			    <div class="popContainer" v-show="changeShow">
			      <transition name="el-fade-in">
			        <div class="transition-box1">
			          <el-form
			            ref="form"
			            :model="sizeForm2"
			            label-width="80px"
			            size="mini"
			          >
			            <div class="act_tit">修改弹窗内容	</div>
			           <el-form-item label="弹窗名称：" label-width="120px">
			              <el-input v-model="sizeForm2.name"></el-input>
			            </el-form-item>
			           <el-form-item label="弹窗内容：" label-width="120px">
			             <el-input v-model="sizeForm2.content"></el-input>
			           </el-form-item>
			           			         
			       
			
			            <el-form-item size="large">
			              <el-button type="primary" @click="onChange">修改</el-button>
			              <el-button @click="changeShow = false">取消</el-button>
			            </el-form-item>
			          </el-form>
			        </div>
			      </transition>
			    </div>
				
				
				<div class="popContainer" v-show="changeShow1">
				  <transition name="el-fade-in">
				    <div class="transition-box1">
				      <el-form
				        ref="form"
				        :model="sizeForm3"
				        label-width="80px"
				        size="mini"
				      >
				        <div class="act_tit">查看弹窗内容	</div>
				       <el-form-item label="弹窗名称：" label-width="120px">
				          <el-input v-model="sizeForm3.name" disabled></el-input>
				        </el-form-item>
				       <el-form-item label="弹窗内容：" label-width="120px">
				         <el-input v-model="sizeForm3.content" disabled></el-input>
				       </el-form-item>
				       			         
				   
							
				        <el-form-item size="large">
				          <el-button @click="changeShow1 = false">关闭</el-button>
				        </el-form-item>
				      </el-form>
				    </div>
				  </transition>
				</div>
			  </div>
			</div>
        <el-table
          :data="tableData"
          border
          style="width: 85%; margin-left: 7.5%; text-align: center"
        >
          <el-table-column prop="title" label="弹窗标题" align="center">
          </el-table-column>
          <el-table-column prop="content" label="弹窗内容" align="center">
          </el-table-column>
        
          <el-table-column
            prop="operation"
            label="操作"
            width="180"
            align="center"
          >
            <template slot-scope="scope">
            
			<span
			  style="margin-left: 10px; cursor: pointer"
			  v-show="storeShow"
			  @click="
			    say(
				  
			      scope.row.title,
			      scope.row.content,
			    )
			  "
			>
			  查看
			</span>
              <span
                style="margin-left: 10px; cursor: pointer"
                v-show="storeShow"
                @click="
                  edit(
				    scope.row.pop_id,
                    scope.row.title,
                    scope.row.content,
                  )
                "
              >
                编辑
              </span>
              <span
                v-show="storeShow"
                style="margin-left: 10px; cursor: pointer"
                @click="delActive(scope.row.pop_id)"
              >
                删除</span
              >
            </template>
          </el-table-column>
        </el-table>
		<div class="block">
		  <el-pagination
		    @size-change="handleSizeChange"
		    @current-change="handleCurrentChange"
		    :current-page="currentPage4"
		    :page-sizes="[10, 20, 30, 40]"
		    :page-size="pageSize"
		    layout="total, sizes, prev, pager, next, jumper"
		    :total="activeListTotal"
		  >
		  </el-pagination>
		</div>
		</div>
      </div>
      <!-- 分页 -->
   
    </frame>
  </div>
</template>

<script>
import frame from "../public/Frame.vue";

export default {
  data() {
    return {
      top1: "11-7",
      top2: ["11"],
      flag: 1,
      admin_type: "",
      activeTitle: "", //活动名称搜索的input框的值
      active_status: "", //选择活动状态
      merchant_status: "", //选择商户
      merchantShow: false, //商户个别权限的隐藏
      storeShow: false, //门店权限的隐藏
      show: false,
      changeShow: false,
	  changeShow1:false,
      businessList: [{}],
      business_id: "",
      storeList: [{}],
      storeid: "",
      //添加活动
      sizeForm: {
        name: "",
        content: "",
      },
	  sizeForm3: {
	    name: "",
	    content: "",
	  },
      //修改活动
      sizeForm2: {
     pop_id:'',
     name:'',
     content:'',
   
      },
      cur_page: 0, //设置一个默认值
      tableData: [], //表格渲染数据
      activeListTotal: 10, //表格总条数
      //总页数
      currentPage4: 1,
      currentpage: "",
      pageSize: 10,
      value2: true,
    };
  },
  methods: {
    //活动名称查询按钮
  
    //选择门店切换列表
	
    merchantChange(business_id) {
      console.log(business_id);
   this.business_id=business_id
    },
  screen(){
   this.$request
        .popList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          store_id:this.business_id,
          page: 1,
          limit: this.pageSize,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.tableData = res.data.data;
            this.activeListTotal = res.data.count;
          }
        });
  },
  onSubmit() {
    if (
      this.sizeForm.name == "" ||
      this.sizeForm.content == "" 
    ) {
      alert("弹窗名称不能为空，内容不能为空");
      
    } else {
      this.$request
        .setPop({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
		  admin_type:this.$storage.getLocal("type"), //身份
          title:this.sizeForm.name,
		  content:this.sizeForm.content,
          store_id: this.business_id,
		  pop_id:'',
        })
        .then((res) => {
          if (res.code == 0) {
            (this.sizeForm.name = ""),
              (this.sizeForm.content = ""),
              (this.show = false);
          this.$request
               .popList({
                 token: this.$storage.getLocal("token"), //登陆进来的tooken
                 admin_type: this.$storage.getLocal("type"), //身份
                 store_id:this.business_id,
                 page: 1,
                 limit: this.pageSize,
               })
               .then((res) => {
                 console.log(res);
                 if (res.code == 0) {
                   this.tableData = res.data.data;
                   this.activeListTotal = res.data.count;
                 }
               });
          } else {
            alert(res.msg);
          }
        });
    }
  },
    //刷新页面按钮
    refresh() {
      location.reload();
    },
    // 监听选择商户变化
    currStationChange() {
      this.$request
        .businessStoreList({
          business_id: this.business_id,
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
        })
        .then((res) => {
          this.storeList = res.data;
        });
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.pageSize = parseInt(`${val}`);
      this.$request
        .activeList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          status: 4, //活动状态
          type: 1, //1是秒杀，2是团购
          active_title: "",
          business_id: "",
          page: 1,
          limit: this.pageSize,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.tableData = res.data.data;
            this.activeListTotal = res.data.count;
          }
        });
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.currentpage = `${val}`;
      this.$request
        .activeList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          status: 4, //活动状态
          type: 1, //1是秒杀，2是团购
          active_title: "",
          business_id: "",
          page: this.currentpage,
          limit: this.pageSize,
        })
        .then((res) => {
          console.log(res);
          if (res.code == 0) {
            this.tableData = res.data.data;
            this.activeListTotal = res.data.count;
            console.log(this.tableData);
          }
        });
    },
  
 
    //编辑商品
    edit(
      pop_id,
      title,
      content,
    ) {
     
     
      this.changeShow = true;
      this.sizeForm2.pop_id = pop_id;
      this.sizeForm2.name = title;
      this.sizeForm2.content = content;

      // this.sizeForm2.resource = '1';
      console.log(this.sizeForm2);

    },
	say(
	title,
	content,
	)
	{
		this.changeShow1 = true;
		this.sizeForm3.name = title;
		this.sizeForm3.content = content;
		
	},
    //编辑活动的提交按钮
    onChange() {
      if (
        this.sizeForm2.name == ""||
		 this.sizeForm2.content==""/* ||
    */
      ) {
        alert("弹窗名称不能为空，弹窗内容不能为空");
        console.log("不能为空");
      } else {
       
       this.$request
         .setPop({
           token: this.$storage.getLocal("token"), //登陆进来的tooken
       		  admin_type:this.$storage.getLocal("type"), //身份
              title:this.sizeForm2.name,
       		  content:this.sizeForm2.content,
              store_id: this.business_id,
       		  pop_id:this.sizeForm2.pop_id,
         })
         .then((res) => {
           if (res.code == 0) {
             (this.sizeForm2.name = ""),
               (this.sizeForm2.content = ""),
               (this.changeShow = false);
               this.$request
                .popList({
                  token: this.$storage.getLocal("token"), //登陆进来的tooken
                  admin_type: this.$storage.getLocal("type"), //身份
                  store_id:this.business_id,
                  page: 1,
                  limit: this.pageSize,
                })
                .then((res) => {
                  console.log(res);
                  if (res.code == 0) {
                    this.tableData = res.data.data;
                    this.activeListTotal = res.data.count;
                  }
                });
           } else {
             alert(res.data.msg);
           }
         });
      }
    },
    //删除商品
    delActive(pop_id) {
     
      this.$request.deletePop({
          token: this.$storage.getLocal("token"),
          pop_id: pop_id,
        })
        .then((res) => {
      if (res.code == 0) {
     
          this.$request
           .popList({
             token: this.$storage.getLocal("token"), //登陆进来的tooken
             admin_type: this.$storage.getLocal("type"), //身份
             store_id:this.business_id,
             page: 1,
             limit: this.pageSize,
           })
           .then((res) => {
             console.log(res);
             if (res.code == 0) {
               this.tableData = res.data.data;
               this.activeListTotal = res.data.count;
             }
           });
      } else {
        alert(res.data.msg);
      }
        });
    },


    merchantStatus(active_status) {
      console.log(active_status);
      this.$request
        .activeList({
          token: this.$storage.getLocal("token"), //登陆进来的tooken
          admin_type: this.$storage.getLocal("type"), //身份
          status: this.active_status, //活动状态
          type: 1, //1是秒杀，2是团购
          active_title: "",
          business_id: "",
          page: 1,
          limit: this.pageSize,
        })
        .then((res) => {
          this.tableData = res.data.data;
          this.activeListTotal = res.data.count;
          // }
        });
    },
  },

  created() {
    this.$request.businessList({}).then((res) => {
      console.log(res);
      this.businessList = res.data;
      // this.
    });
    this.admin_type = this.$storage.getLocal("type");
    console.log("business_id", this.business_id);
    // let business_id = this.business_id;
    if (this.admin_type == 1) {
      this.merchantShow = true;
      this.storeShow = true;
    }
    if (this.admin_type == 2) {
		this.$request
		     .popList({
		       token: this.$storage.getLocal("token"), //登陆进来的tooken
		       admin_type: this.$storage.getLocal("type"), //身份
		       store_id:this.business_id,
		       page: 1,
		       limit: this.pageSize,
		     })
		     .then((res) => {
		       console.log(res);
		       if (res.code == 0) {
		         this.tableData = res.data.data;
		         this.activeListTotal = res.data.count;
		       }
		     });
      this.storeShow = true;
    }

 
  },
  components: {
    frame,
  },
};
</script>

<style scoped>
.head {
  width: 100%;
  height: 45px;
  line-height: 45px;
  background-color: rgb(243, 243, 243);
}
.left,
.right {
  display: inline;
}
.left {
  float: left;
  margin-left: 210px;
}
.left_tit {
  border-left: 5px solid red;
  font-size: 22px;
  padding: 5px;
}
.refresh {
  /* border:1px solid red ; */
  float: right;
  margin-right: 10%;
  margin-top: 2px;
}
.screen {
  /* border: 1px solid #ccc; */
  /* margin-top: 15px; */
  /* width: 85%; */
  margin-left: 200px;
  height: 90px;
  background-color: rgb(245,247,249);
  padding-top: 10px;
  padding-bottom: 25px;
}
.screen_tit {
  height: 40px;
  background-color: rgb(243, 243, 243);
}
.screen_left {
  float: left;
  margin-left: 50px;
  margin-top: 10px;
}
.screen_right {
  float: right;
  margin-top: 6px;
  margin-right: 10px;
}
.screen_cont {
/*  margin-left: 20px;
  padding-top: 10px; */
  display: flex;
  /* font-size: 18px; */
  width: 97.5%;
  background-color: white;
  /* margin-top: 20px; */
  /* border: 1px solid red; */
  margin-left: 1.25%;
  /* margin-top: 20px; */
  padding-right: 11%;
  padding-top: 10px;
  padding-bottom: 20px;
}
.popContainer {

  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  z-index: 3;
}
.transition-box {
	
  position: absolute;
  z-index: 2;
  top: 150px;
  left: 27%;
  width: 800px;
  height: 500px;

  box-shadow: 0px 0px 10px #888888;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
}
.transition-box1 {
	
  position: absolute;
  z-index: 2;
  top: 150px;
  left: 27%;
  width: 800px;
  height: 300px;

  box-shadow: 0px 0px 10px #888888;
  border-radius: 10px;
  background-color: #fff;
  text-align: center;
  color: #fff;
  padding: 40px 20px;
  box-sizing: border-box;
}
.act_tit {
  width: 800px;
  height: 50px;
  color: #000000;
  background-color: #F5F7FA;
  margin-top: -40px;
  margin-left: -20px;
  padding-left: 10px;
  border-radius: 10px 10px 0px 0;
  margin-bottom: 50px;
  font-size: 20px;
  line-height: 50px;
  display: flex;
  justify-content: space-between;
}

.table_head {
  height: 40px;
  margin-left: 200px;
  width: 85%;
  /* border: 1px solid #ccc; */
  display: flex;
  justify-content: space-between;
  padding-top: 5px;
}
.table_head_left {
  margin-top: 5px;
  margin-left: 20px;
}
.table_head_right li {
  float: right;
  height: 30px;

  list-style: none;
  margin-right: 10px;
}
.block {
  margin-top: 30px;
}
.table
	{
	 background-color: rgb(245,247,249);
	 padding-top: 10px;
	 padding-bottom: 25px;
	 min-height: 565px;
	}
	
	.bck
	{
		width: 85%;
		background-color: white;
		/* margin-top: 20px; */
		/* border: 1px solid red; */
		margin-left: 14%;
		/* margin-top: 20px; */
		padding-top: 20px;
		padding-bottom: 20px;
		/* line-height: 20px; */
	}
</style>